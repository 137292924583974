import { PaginatedResponse } from '@eagle/api-types';
import { Dashboard, DashboardType } from '@eagle/core-data-types';
import { APP_BANNER_HEIGHT, APP_BAR_HEIGHT, Breadcrumbs, DashboardSwitcher, ErrorPage, MiddleSpinner, NotFound, SIDEBAR_FULL_WIDTH, SIDEBAR_WIDTH, SisenseDashboard, Undefinable, useAuthenticated, usePromise, useSidebarPinContext, useTitle } from '@eagle/react-common';
import { AppBar, Box, Link, Toolbar, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHref, useParams } from 'react-router-dom';

export const ReportsPage: FC = () => {
  const { t } = useTranslation(['track']);
  const { isPinned } = useSidebarPinContext();
  const { axios } = useAuthenticated();
  const { dashboardId } = useParams();
  const [dashboardName, setDashboardName] = useState<Undefinable<string>>();
  const renderTitle = useCallback(() => {
    return (
      <DashboardSwitcher dashboardId={dashboardId} type={DashboardType.REPORT} sx={{flex: 1, maxWidth: 'calc(100% - 40px)'}}>
        <Typography noWrap variant="h6" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {dashboardName}
        </Typography>
      </DashboardSwitcher>
    );
  }, [dashboardName, dashboardId]);
  useTitle(renderTitle(), true);
  const theme = useTheme();
  const href = useHref('/reports');

  const [dashboard, dashboardError, dashboardState] = usePromise(async () => {
    const filter = { '_id': dashboardId };
    const result = await axios.get<PaginatedResponse<Dashboard>>('/api/v2/my/dashboards', {
      params: { filter },
    });
    return result.data?.items.length ? result.data?.items[0] : undefined;
  }, [axios, dashboardId]);

  useEffect(() => {
    if (!dashboard) return;
    setDashboardName(dashboard.display);
  }, [dashboard]);

  if (dashboardState === 'pending') return <MiddleSpinner />;
  if (dashboardError) return <ErrorPage error={dashboardError} />;
  if (!dashboard) return <NotFound />;

  return (
    <Box sx={{ width: 1, '.showAppBanner & .MuiAppBar-root': { mt: `${APP_BANNER_HEIGHT}px` } }}>
      <AppBar sx={{ display: { xs: 'none', sm: 'flex' }, height: APP_BAR_HEIGHT, left: isPinned ? SIDEBAR_FULL_WIDTH : SIDEBAR_WIDTH, width: 'unset' }}>
        <Toolbar>
          <Breadcrumbs color={theme.palette.common.white}>
            <Link color={theme.palette.common.white} href={href}>
              {t('track:page.reports.title')}
            </Link>
            <DashboardSwitcher dashboardId={dashboardId} type={DashboardType.REPORT}>
              <Typography>{dashboardName}</Typography>
            </DashboardSwitcher>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 1, height: 1, maxHeight: { xs: 'unset', sm: `calc(100% - ${APP_BAR_HEIGHT}px)` }, overflow: { xs: 'unset', sm: 'auto' }, mt: { xs: 0, sm: `${APP_BAR_HEIGHT}px` } }}>
        <SisenseDashboard dashboardId={dashboardId ?? 'error'} />
      </Box>
    </Box>
  );
};
